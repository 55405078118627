/* custome font size */

h1 {
  font-size: 48px !important;
  line-height: 1.4;
  letter-spacing: -0.1;
}

h2 {
  font-size: 35px !important;
  line-height: 1.3 !important;
  letter-spacing: -0.1 !important;
}

h3 {
  font-size: 36px;
  line-height: 1.4;
  letter-spacing: -0.1;
}

h4 {
  font-size: 2.8rem;
  line-height: 1.5;
  letter-spacing: -0.1;
}

h5 {
  font-size: 22px !important;
  line-height: 1.4 !important;
  letter-spacing: -0.1;
}

.body-2 {
  font-size: 18px;
  letter-spacing: -0.1;
}

@media (max-width: 576px) {
  h1 {
    font-size: 48px !important;
    line-height: 1.4;
    letter-spacing: -0.1;
  }

  h2 {
    font-size: 28px !important;
    line-height: 1.4 !important;
    letter-spacing: -0.1;
  }

  h3 {
    font-size: 36px;
    line-height: 1.4;
    letter-spacing: -0.1;
  }

  h4 {
    font-size: 2.8rem;
    line-height: 1.5;
    letter-spacing: -0.1;
  }

  h5 {
    font-size: 22px !important;
    line-height: 1.4 !important;
    letter-spacing: -0.1;
  }

  .body-2 {
    font-size: 18px;
    letter-spacing: -0.1;
  }
}

.icon-color {
  color: #09ae61;
}

.mw-85 {
  max-width: 85%;
}

@media (max-width: 576px) {
  .mw-85 {
    max-width: 100%;
  }
}

.section_stepper {
  padding-top: 20px;
  padding-bottom: 40px;
}

.fix_border_top {
  margin-top: -1px;
}

.h-10 {
  height: 6rem !important;
}

/* .-mb-2 {
  margin-bottom: -5px;
} */

.light_card {
  width: 100%;
  height: 20rem;
  background-color: #eff3fe;
  padding: 16px 20px 20px 16px;
  border-radius: 8px;
  transition: transform 0.5s;
  /* min-height: 158px; */
  cursor: pointer;
  perspective: 1000px;
}

.card_inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.5s;
  transform-style: preserve-3d;
}

.light_card:hover .card_inner {
  transform: rotateY(180deg);
}

.card_front,
.card_back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.card_image {
  width: 200px;
}

.card_back {
  /* background-color: #dfe3f8; */
  transform: rotateY(180deg);
  /* padding: 10px; */
  text-align: center;
  border-radius: 8px;
  font-size: 12px;
}

.card_back_text {
  flex-grow: 1;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background: #f0f0f0;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 10px;
    border: 2px solid #f0f0f0;
  }

  scrollbar-width: thin;
  scrollbar-color: #888 #f0f0f0;
}

.card_back_text > p {
  background-color: #dfe3f8;
}

.section_stepper_bottom {
  position: relative;
  display: flex;
  width: 103%;
}

.blue_bottom_stepper {
  margin-top: 60px;
  padding-top: 67px;
  background-image: linear-gradient(180deg, #eef3fd, white);
  padding-bottom: 4rem;
}

@media (max-width: 576px) {
  .blue_bottom_stepper {
    margin-top: 0px;
    padding-top: 67px;
    background-image: linear-gradient(180deg, #eef3fd, white);
    padding-bottom: 2rem;
  }
}

.back-blue-1 {
  background-color: #36337d;
}

.active :hover {
  color: #00ccf3 !important;
  filter: drop-shadow(1px 1px 20px #84acfb);
}

.active_icon :hover {
  filter: drop-shadow(1px 1px 20px #84acfb);
}

.responsive-heading {
  text-align: left;
  max-width: 85%;
  text-align: left;
}

@media (max-width: 576px) {
  .responsive-heading {
    max-width: 100%;
    text-align: center;
  }
}

.hero_button {
  overflow: hidden;
  position: relative;
  display: inline-block;
  padding: 10.5px 10.6px;
  border-radius: 8px;
  background-color: #eff3fe;
  /* background-color: #d8cbf6; */
  color: #36337d !important;
  display: flex;
  gap: 20px;
  align-items: center;
  border: none;
}

.navigation__logo-wrapper {
  position: absolute;
  z-index: -1;
  background-image: url(../public/images/logo-behind-block.svg);
  background-size: cover;
  width: 24.5rem;
  height: 13.2rem;
  top: 0;
  padding-top: 10px;
  padding-left: 3rem;
  cursor: pointer;
  z-index: 99;
}
.navigation__logo-wrapper_mobile {
  display: none;
}

@media (max-width: 769px) {
  .navigation__logo-wrapper {
    padding-top: 70px;
    padding-left: 0;
    display: none;
  }
  .navigation__logo-wrapper_mobile {
    /* position: absolute; */
    right: 0;
    display: block;
    padding-block: 0.5;
  }
}

.hero-home {
  position: relative;
  overflow: hidden;
  padding-top: 6.7rem;
  padding-bottom: 12rem;
}

.contact_home {
  position: relative;
  overflow: hidden;
  padding-top: 6.7rem;
}

.offering_home {
  position: relative;
  overflow: hidden;
  padding-top: 6.7rem;
  padding-bottom: 4rem;
}

@media (max-width: 769px) {
  .hero-home {
    position: relative;
    overflow: hidden;
    padding-top: 0rem;
    padding-bottom: 2rem;
  }
  .contact_home {
    position: relative;
    overflow: hidden;
    padding-top: 0rem;
  }

  .offering_home {
    position: relative;
    overflow: hidden;
    padding-top: 0rem;
    padding-bottom: 3rem;
  }
}

.hero-home__phone-frame {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  /* height: 32.5rem; */
  width: 100%;
}
.frame_content {
  width: 400px;
  position: relative;
  border-radius: 25px !important;
  float: right;
  overflow: hidden;
  margin-top: 115px;
  background-color: transparent !important;
  height: 100% !important;
  border: none !important;
}

@media (max-width: 769px) {
  .frame_content {
    width: 100%;
    margin-top: 10px;
    height: 360px !important;
  }
}

.hero-home__shapes-wrapper {
  position: absolute;
  margin-top: 30px;
  top: 0;
  right: 0;
  max-width: 370px;
  z-index: -1;
}

.hero-home__plus-shapes-wrapper {
  position: absolute;
  bottom: -3rem;
  z-index: -3;
  left: -40rem;
}

.hero-home__blue-shapes-wrapper {
  position: absolute;
  bottom: -0.1rem;
  width: 103%;
  left: -0.2rem;
}

.blue_top_stepper {
  margin-bottom: -0.2rem;
  margin-left: -0.2rem;
  width: 150%;
}

@media (max-width: 769px) {
  .hero-home__blue-shapes-wrapper {
    bottom: 0rem;
  }
  .blue_top_stepper {
    margin-left: -0.5rem;
  }
}

.three-col-stepper__image {
  width: 250px;
  height: 250px;
  border-radius: 12px;
  overflow: hidden;
  margin-bottom: 30.9px;
}

.three-col-stepper__content {
  display: flex;
  flex-direction: column;
  font-size: small;
}

.three-col-stepper__content > p {
  text-align: justify !important;
}

.three-col-stepper__content > a {
  text-align: justify !important;
  font-size: medium;
}

@media (max-width: 769px) {
  .three-col-stepper__image {
    width: 100%;
    height: auto;
    margin-bottom: 50.9px;
  }

  .three-col-stepper__content {
    text-align: center !important;
    max-width: 100% !important;
  }
}

.team-stepper__image {
  width: 250px;
  height: 250px;
  border-radius: 12px;
  overflow: hidden;
  margin-bottom: 10.9px;
}

.team-stepper__content {
  width: 250px;
}

.main-navbar {
  position: relative;
  width: 100%;
  padding-top: 3.5rem !important;
  background-color: transparent;
  position: block;
}

.navbar-toggler {
  background-color: #f0f0f0 !important;
}

@media (max-width: 769px) {
  .main-navbar {
    background-color: #32347a;
    padding: 0.5rem !important;
  }
}

/* accordion css */

.hero-contact__image-wrapper {
  position: absolute;
  top: 5rem;
  width: 42%;
  right: 0;
}

@media (max-width: 769px) {
  .hero-contact__image-wrapper {
    position: absolute;
    top: 5rem;
    width: 42%;
    right: 0;
    display: none;
  }
}

/* css for offering */

.offering-card {
  transition: transform 0.3s ease-in-out;
  overflow: hidden;
}

.offering-card:hover {
  background-color: #d3d3d32e;
}

/* .offering-card img {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  max-height: 200px;
  object-fit: cover;
}

.offering-card .card-body {
  padding: 20px;
  background-color: white;
  border-radius: 0 0 10px 10px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.offering-card .card-title {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.offering-card .card-text {
  font-size: 1rem;
  color: #666;
  flex-grow: 1;
  margin-bottom: 0;
} */
